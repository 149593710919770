<template>
  <div class="">
    <!-- section-1  -->
    <v-card class="bgs rounded-0" flat  >
      <div class="" id="home-tops">
        <v-row align="center" class="ma-0">
          <v-col cols="12" md="5" sm="12" class="px-0">
            <div class="left">
              <p class="text-body-1">{{ $t("快速搭建你的在线商店") }}</p>
              <p class="titles mb-0">{{ $t("全球贸易平台！") }}</p>
              <p class="titles1 mb-0">
                {{
                  $t("使用taoify建立您的在线业务在全球任何地方销售您的产品")
                }}
              </p>
              <p class="primary--text mt-3 text-body-2 mb-0">
                {{
                  $t(
                    "是一款免费的易于使用的跨境在线商店系统，可以将您的商品销往全世界。"
                  )
                }}
              </p>
              <div class="mt-8">
                <router-link to="/sign-up" class="d-flex">
                  <v-card
                    class="primary-background font18 px-12 rounded-pill cursor white--text"
                    height="51"
                    flat
                    >{{ $t("立即免费开通") }}</v-card
                  >
                </router-link>
              </div>
              <p class="mb-0 text-caption mt-3">
                {{ $t("taoify在线商店支持全球各种语言，全球各种货币。") }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="7" sm="12" class="px-0 d-flex justify-end py-10">
            <div>
              <v-img
                class="gif"
                max-width="737"
                src="@/assets/images/home/icon1.png"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-container>
      <p class="font32 weight500 mb-11 text-center">
        {{ $t("taoify在线商店五大优势") }}
      </p>
      <v-row justify="center" justify-sm="center" justify-md="space-between">
        <v-card
          width="220"
          min-height="209"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/home/youshi1.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("完全免费") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{ $t("taoify店铺完全免费使用，无月租，无提成，无手续费") }}
          </p>
        </v-card>
        <v-card
          width="220"
          min-height="209"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/home/youshi2.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("完美适配移动端") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{ $t("专为移动端定制模板，用户体验更好") }}
          </p>
        </v-card>
        <v-card
          width="220"
          min-height="209"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/home/youshi3.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("全球本地收款") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{ $t("支持全球本地化收款方式，多种支付渠道整合") }}
          </p>
        </v-card>
        <v-card
          width="220"
          min-height="209"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/home/youshi4.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("一键对接全球配送") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{
              $t("支持全球数百家国际物流一键绑定，自动履单。服务采购质检寄送")
            }}
          </p>
        </v-card>
        <v-card
          width="220"
          min-height="209"
          class="px-5 py-6 rounded-xl text-center elevation-5 mb-5"
        >
          <v-img
            width="44"
            class="mx-auto"
            src="@/assets/images/home/youshi5.png"
          ></v-img>
          <p class="font20 weight500 mt-3 mb-0">{{ $t("海量商品导入") }}</p>
          <p class="mb-0 mt-3 text-left">
            {{ $t("支持淘宝1688官方接口几十亿商品导入，稳定快速。") }}
          </p>
        </v-card>
      </v-row>
      <p class="font20 weight500 text-center" style="margin-top: 80px">
        {{ $t("帮助全球商家探索新型销售模式") }}
      </p>

      <v-row class="ma-0">
        <v-col cols="6" md="3" class="">
          <v-card flat width="160" class="mx-auto">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  width="140"
                  class=""
                  src="@/assets/images/home/icon2.svg"
                ></v-img>
              </div>
            </div>
            <p class="text-center font20 weight500 mb-2">{{ $t("零售者") }}</p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("适用于线上零售商") }}
            </p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("小成本开店") }}
            </p>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" class="">
          <v-card flat width="160" class="mx-auto">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  width="140"
                  class=""
                  src="@/assets/images/home/icon3.svg"
                ></v-img>
              </div>
            </div>
            <p class="text-center font20 weight500 mb-2">{{ $t("批发商") }}</p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("为想做中国商品批发的") }}
            </p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("创业者提供解决方案") }}
            </p>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" class="">
          <v-card flat width="160" class="mx-auto">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  width="140"
                  class=""
                  src="@/assets/images/home/icon4.svg"
                ></v-img>
              </div>
            </div>
            <p class="text-center font20 weight500 mb-2">
              {{ $t("商业公司") }}
            </p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("定制专属业务模式") }}
            </p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("在全球销售中国商品") }}
            </p>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" class="">
          <v-card flat width="160" class="mx-auto">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  width="140"
                  class=""
                  src="@/assets/images/home/icon5.svg"
                ></v-img>
              </div>
            </div>
            <p class="text-center font20 weight500 mb-2">
              {{ $t("物流企业") }}
            </p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("轻松拓展业务范围") }}
            </p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("中国商品销售触手可得") }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <p
        class="font32 font-weight-bold text-center mb-0"
        style="margin-top: 100px"
      >
        {{ $t("拓展渠道难？建站成本高？引流难？销量低？无效率？无成交？") }}
      </p>
      <p class="font20 weight500 text-center mt-2 mb-11">
        {{
          $t(
            "taoify一站式全球跨境电商平台，洞察行业最新动态，解决供应商出海痛点"
          )
        }}
      </p>
      <v-row class="my-0">
        <v-col cols="12" md="4" class="pa-0">
          <v-card
            style="margin-top: 23px"
            color="#F7F6FF"
            class="rounded-0 rounded-l-xl"
            flat
          >
            <v-card
              height="70"
              color="#EAEAFF"
              class="rounded-r-0 font20 font-weight-bold text-center"
              style="line-height: 70px"
              flat
            >
              {{ $t("功能展示（部分）") }}
            </v-card>
            <div class="gongneng">{{ $t("建店周期") }}</div>
            <div class="gongneng">{{ $t("是否有店铺成本") }}</div>
            <div class="gongneng">{{ $t("店铺装修") }}</div>
            <div class="gongneng">{{ $t("店铺冻结") }}</div>
            <div class="gongneng">{{ $t("店铺冻结") }}</div>
            <div class="gongneng">{{ $t("店铺语言") }}</div>
            <div class="gongneng">{{ $t("如何选品上架") }}</div>
            <div class="gongneng">{{ $t("选品来源") }}</div>
            <div class="gongneng">{{ $t("如何收款") }}</div>
            <div class="gongneng">{{ $t("如何处理订单") }}</div>
            <div class="gongneng">{{ $t("如何发货") }}</div>
            <div class="gongneng">{{ $t("如何添加文章说明") }}</div>
            <div class="gongneng">{{ $t("店铺推广") }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pa-0">
          <v-card
            color="#F7F6FF"
            style="margin-top: 23px"
            class="rounded-0"
            flat
          >
            <v-card
              flat
              height="70"
              color="#EAEAFF"
              class="d-flex justify-center align-center rounded-0"
            >
              <div>
                <v-img
                  width="98"
                  src="@/assets/images/home/shopify.png"
                ></v-img>
              </div>
            </v-card>
            <div class="gongneng">{{ $t("1-2周") }}</div>
            <div class="gongneng">{{ $t("有，1500$/月") }}</div>
            <div class="gongneng">{{ $t("自己学习配置&购买模板") }}</div>
            <div class="gongneng">{{ $t("会") }}</div>
            <div class="gongneng">{{ $t("多平台切换") }}</div>
            <div class="gongneng">{{ $t("支持21种语言") }}</div>
            <div class="gongneng">{{ $t("自己选品&上架") }}</div>
            <div class="gongneng">
              {{ $t("自己找货，手动添加&借助收费应用选品") }}
            </div>
            <div class="gongneng">{{ $t("需有自己的paypal账户") }}</div>
            <div class="gongneng">{{ $t("自己采购") }}</div>
            <div class="gongneng">{{ $t("自己对接物流") }}</div>
            <div class="gongneng">{{ $t("接入shopify博客") }}</div>
            <div class="gongneng">{{ $t("多平台切换") }}</div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pa-0">
          <v-card color="#F7F6FF" class="rounded-xl" flat>
            <v-img
              src="@/assets/images/home/gongnengbg.png"
              height="740"
              width="400"
            >
              <div
                style="height: 94px; padding-top: 45px"
                class="d-flex justify-center"
              >
                <div>
                  <v-img width="258" src="@/assets/images/logo.svg"></v-img>
                </div>
              </div>
              <div class="gongneng">{{ $t("5分钟") }}</div>
              <div class="gongneng">{{ $t("无") }}</div>
              <div class="gongneng">
                {{ $t("免费模板&自定义模板&定制化开发&购买模板") }}
              </div>
              <div class="gongneng">{{ $t("不会") }}</div>
              <div class="gongneng">{{ $t("一个平台全部搞定") }}</div>
              <div class="gongneng">{{ $t("支持所有语言") }}</div>
              <div class="gongneng">{{ $t("taoify选品&上架") }}</div>
              <div class="gongneng">
                {{ $t("taoify平台一键上传淘宝、1688平台商品") }}
              </div>
              <div class="gongneng">{{ $t("可支持任何收款方式") }}</div>
              <div class="gongneng">{{ $t("自动采购") }}</div>
              <div class="gongneng">{{ $t("taoify平台搞定") }}</div>
              <div class="gongneng">{{ $t("taoify平台自定义编辑") }}</div>
              <div class="gongneng">{{ $t("一个平台全部搞定") }}</div>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <p
        class="primary--text font20 text-center mb-0"
        style="margin-top: 100px"
      >
        {{ $t("建立在线商店") }}
      </p>
      <p class="mb-0 mt-2 font32 font-weight-bold text-center">
        {{ $t("您的商店，您来做主") }}
      </p>
      <v-card flat class="mt-11" color="#F1F0FF">
        <v-img class="gif" src="@/assets/images/home/1.gif"></v-img>
      </v-card>
      <v-row class="my-0 mt-11">
        <v-col cols="6" md="4">
          <div class="font20 font-weight-bold d-flex align-center">
            <v-card
              flat
              width="16"
              height="16"
              color="primary"
              class="rounded-1 mr-2"
            ></v-card>
            {{ $t("建立在线店面") }}
          </div>
          <p class="my-3">
            {{
              $t(
                "只需要提交相关信息，taoify自动为您创建商店，把您想要销售中国商品的愿景变为现实，您将会是下一个大卖家"
              )
            }}
          </p>
          <a href="/sign-up" class="text-decoration-underline">{{
            $t("在线创建商店")
          }}</a>
        </v-col>
        <v-col cols="6" md="4">
          <div class="font20 font-weight-bold d-flex align-center">
            <v-card
              flat
              width="16"
              height="16"
              color="primary"
              class="rounded-1 mr-2"
            ></v-card>
            {{ $t("打造您想要的品牌") }}
          </div>
          <p class="my-3">
            {{
              $t(
                "由专业的设计师团队创建的精美模板,可在社区自定义装扮，打造个性小店"
              )
            }}
          </p>
          <a href="/sign-up" class="text-decoration-underline">{{
            $t("在线创建商店")
          }}</a>
        </v-col>
        <v-col cols="6" md="4">
          <div class="font20 font-weight-bold d-flex align-center">
            <v-card
              flat
              width="16"
              height="16"
              color="primary"
              class="rounded-1 mr-2"
            ></v-card>
            {{ $t("开通金流物流") }}
          </div>
          <p class="my-3">
            {{
              $t(
                "使用taoify给您提供的全球金流物流渠道，轻松开通您自己的金流物流渠道。"
              )
            }}
          </p>
          <a href="/sign-up" class="text-decoration-underline">{{
            $t("在线创建商店")
          }}</a>
        </v-col>
      </v-row>
    </v-container>
    <v-card
      style="margin-top: 138px"
      color="#F7F7F7"
      flat
      class="rounded-0 py-15"
    >
      <v-container>
        <v-row class="" align="center">
          <v-col cols="12" md="5">
            <p class="font32 weight500 mb-0">
              {{ $t("在任何时间地点销售您的产品") }}
            </p>
            <p class="my-6 text--secondary">
              {{
                $t(
                  "使用taoify在线商店，可以分享到各大社交平台，集成taoify销售渠道，将您商店中的商品销往世界各地"
                )
              }}
            </p>
            <v-row class="ma-0">
              <router-link to="/sign-up">
              <v-card
                class="d-flex align-center py-3 px-8 rounded-pill cursor primary-background font20 white--text"
                style="line-height: 30px"
                flat
                >{{ $t("开始销售") }}
                <v-img
                  src="@/assets/images/home/right.png"
                  class="ml-7"
                  width="28"
                ></v-img>
              </v-card></router-link>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="12" md="6" class="d-flex justify-end align-center">
            <div>
              <v-img width="314" src="@/assets/images/home/home1.png"></v-img>
            </div>
            <div class="ml-6">
              <v-img width="283" src="@/assets/images/home/home2.png"></v-img>
              <v-img
                class="mt-6"
                width="283"
                src="@/assets/images/home/home3.png"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container class="pt-0">
      <v-row class="ma-0" align="center">
        <v-col cols="12" md="6" class="d-flex align-center">
          <div>
            <v-img
              max-width="636"
              max-height="640"
              class="gif"
              src="@/assets/images/home/2.gif"
            ></v-img>
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="5">
          <p class="mb-4 primary--text">{{ $t("简单的商品上传") }}</p>
          <p class="font32 weight500 mb-0">
            {{ $t("全球最方便快捷的商品上传方式") }}
          </p>
          <p class="my-6 text--secondary">
            {{
              $t(
                "只需要在taoify网站选择好想要销售的商品，点击就能一键上传到你的商店，销售什么商品由你来决定"
              )
            }}
          </p>
          <v-row class="ma-0">
            <router-link to="/sign-up">
            <v-card
              class="d-flex align-center py-3 px-8 rounded-pill cursor primary-background font20 white--text"
              style="line-height: 30px"
              flat
              >{{ $t("成千上万的优质商品任您选择") }}
              <v-img
                src="@/assets/images/home/right.png"
                class="ml-7"
                width="28"
              ></v-img>
            </v-card></router-link>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pt-0">
      <v-row class="ma-0" align="center">
        <v-col cols="12" md="5">
          <p class="mb-4 primary--text">{{ $t("快速可靠的支付方式") }}</p>
          <p class="font32 weight500 mb-0">
            {{ $t("提供安全快捷的支付方式") }}
          </p>
          <p class="my-6 text--secondary">
            {{
              $t(
                "您的顾客在选择好商品之后，可以选择taoify集成的当地的安全支付方式为您快捷收款，解决您的跨境收款难题。"
              )
            }}
          </p>
          <v-row class="ma-0">
            <router-link to="/sign-up">
            <v-card
              class="d-flex align-center py-3 px-8 rounded-pill cursor primary-background font20 white--text"
              style="line-height: 30px"
              flat
              >{{ $t("抓住每一次生意的机会") }}
              <v-img
                src="@/assets/images/home/right.png"
                class="ml-7"
                width="28"
              ></v-img>
            </v-card></router-link>
          </v-row>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="6" class="d-flex justify-end align-center">
          <div>
            <v-img
              max-width="650"
              max-height="650"
              class="gif"
              src="@/assets/images/home/3.gif"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pt-0" style="marign-top: -40px">
      <v-row class="ma-0" align="center">
        <v-col cols="12" md="6" class="d-flex align-center">
          <div>
            <v-img
              max-width="640"
              max-height="640"
              class="gif"
              src="@/assets/images/home/4.gif"
            ></v-img>
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="5">
          <p class="mb-4 primary--text">{{ $t("灵活的物流渠道选择") }}</p>
          <p class="font32 weight500 mb-0">
            {{ $t("灵活的物流渠道选择，把货物安全的交付给您的顾客") }}
          </p>
          <p class="my-6 text--secondary">
            {{
              $t(
                "你可以选择使用taoify集成的全球物流渠道，可以安全快捷的将您的货物交付给您的全球顾客。"
              )
            }}
          </p>
          <v-row class="ma-0">
            <router-link to="/sign-up">
            <v-card
              class="d-flex align-center py-3 px-8 rounded-pill cursor primary-background font20 white--text"
              style="line-height: 30px"
              flat
              >{{ $t("给顾客提供优质的服务") }}
              <v-img
                src="@/assets/images/home/right.png"
                class="ml-7"
                width="28"
              ></v-img>
            </v-card></router-link>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-card color="#F7F7F7" flat style="margin-top: -20px">
      <v-container>
        <v-row class="" align="center">
          <v-col cols="12" md="5">
            <p class="mb-4 primary--text">{{ $t("内置营销工具") }}</p>
            <p class="font32 weight500 mb-4">
              {{ $t("第一次开店也能轻松完成业绩成倍增长") }}
            </p>
            <v-row class="ma-0">
              <router-link to="/sign-up">
              <v-card
                class="d-flex align-center py-3 px-8 rounded-pill cursor primary-background font20 white--text"
                style="line-height: 30px"
                flat
                >{{ $t("使用我们的营销工具") }}
                <v-img
                  src="@/assets/images/home/right.png"
                  class="ml-7"
                  width="28"
                ></v-img>
              </v-card></router-link>
            </v-row>
            <div class="pt-9">
              <v-row class="ma-0" align="center">
                <div>
                  <v-img
                    width="60"
                    src="@/assets/images/home/home5.png"
                  ></v-img>
                </div>
                <div class="ml-2" style="width: 70%">
                  <p class="font20 weight500 mb-2">{{ $t("接触潜在顾客") }}</p>
                  <p class="mb-0 text--secondary">
                    {{ $t("使用社区营销、推广联盟、积分工具等寻找新的购物者") }}
                  </p>
                </div>
              </v-row>
              <v-row class="ma-0 mt-4" align="center">
                <div>
                  <v-img
                    width="60"
                    src="@/assets/images/home/home7.png"
                  ></v-img>
                </div>
                <div class="ml-2" style="width: 70%">
                  <p class="font20 weight500 mb-2">{{ $t("与客户互动") }}</p>
                  <p class="mb-0 text--secondary">
                    {{
                      $t(
                        "自定义电子邮件营销内容，智能AI工具，随时随地和客户联系"
                      )
                    }}
                  </p>
                </div>
              </v-row>
              <v-row class="ma-0 mt-4" align="center">
                <div>
                  <v-img
                    width="60"
                    src="@/assets/images/home/home9.png"
                  ></v-img>
                </div>
                <div class="ml-2" style="width: 70%">
                  <p class="font20 weight500 mb-2">{{ $t("数据分析") }}</p>
                  <p class="mb-0 text--secondary">
                    {{
                      $t(
                        "掌握会员数据，分析受众并持续进行客户洞察，拉新留存转化"
                      )
                    }}
                  </p>
                </div>
              </v-row>
            </div>
          </v-col>
          <v-spacer />
          <v-col cols="12" md="6" class="d-flex justify-end align-center">
            <div>
              <v-img
                max-width="727"
                class="gif"
                src="@/assets/images/home/5.gif"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container class="pt-0">
      <v-row class="ma-0" align="center">
        <v-col cols="12" md="6" class="d-flex align-center">
          <div>
            <v-img
              class="gif"
              max-width="636"
              max-height="640"
              src="@/assets/images/home/6.gif"
            ></v-img>
          </div>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="5">
          <p class="mb-4 primary--text">{{ $t("简单的商品上传") }}</p>
          <p class="font32 weight500 mb-0">
            {{ $t("全球最方便快捷的商品上传方式") }}
          </p>
          <p class="my-6 text--secondary">
            {{
              $t(
                "只需要在taoify网站选择好想要销售的商品，点击就能一键上传到你的商店，销售什么商品由你来决定"
              )
            }}
          </p>
          <v-row class="ma-0">
            <router-link to="/sign-up">
            <v-card
              class="d-flex align-center py-3 px-8 rounded-pill cursor primary-background font20 white--text"
              style="line-height: 30px"
              flat
              >{{ $t("成千上万的优质商品任您选择") }}
              <v-img
                src="@/assets/images/home/right.png"
                class="ml-7"
                width="28"
              ></v-img>
            </v-card></router-link>
          </v-row>
          <div class="pt-9">
            <v-row class="ma-0" align="center">
              <div>
                <v-img width="60" src="@/assets/images/home/home4.png"></v-img>
              </div>
              <div class="ml-2" style="width: 70%">
                <p class="font20 weight500 mb-2">{{ $t("履行每个订单") }}</p>
                <p class="mb-0 text--secondary">
                  {{
                    $t("您商店中所产生的每一个订单，都会由taoify帮您完整履单")
                  }}
                </p>
              </div>
            </v-row>
            <v-row class="ma-0 mt-4" align="center">
              <div>
                <v-img width="60" src="@/assets/images/home/home6.png"></v-img>
              </div>
              <div class="ml-2" style="width: 70%">
                <p class="font20 weight500 mb-2">{{ $t("运营监督") }}</p>
                <p class="mb-0 text--secondary">
                  {{
                    $t(
                      "taoify会跟踪付款和查看实时业务，因此您可以更专注于您的店铺优化"
                    )
                  }}
                </p>
              </div>
            </v-row>
            <v-row class="ma-0 mt-4" align="center">
              <div>
                <v-img width="60" src="@/assets/images/home/home8.png"></v-img>
              </div>
              <div class="ml-2" style="width: 70%">
                <p class="font20 weight500 mb-2">{{ $t("资金管理") }}</p>
                <p class="mb-0 text--secondary">
                  {{
                    $t(
                      "为商家设置专属的资金池，一键查看您的财务信息"
                    )
                  }}
                </p>
              </div>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <p class="font32 weight500 mb-6 mt-16 text-center">
        {{ $t("超百万个企业家选择taoify创建业务商店") }}
      </p>
      <v-row class="ma-0" align="center" justify="space-between">
        <v-col cols="5" md="2" sm="4">
          <p
            class="font24 primary--text d-flex align-center mb-0 justify-center"
          >
            <span class="font48 font-weight-bold">200</span
            ><span class="font24 font-weight-bold ml-1">{{ $t("万") }}+</span>
          </p>
          <p class="text--disabled text-body-1 mb-0 text-center">
            {{ $t("全球商户数量") }}
          </p>
        </v-col>
        <v-card
          width="20"
          height="20"
          class="rounded-pill"
          flat
          color="#FAA9B3"
        ></v-card>
        <v-col cols="5" md="2" sm="4">
          <p
            class="font24 primary--text d-flex align-center mb-0 justify-center"
          >
            <span class="font48 font-weight-bold">100</span
            ><span class="font24 font-weight-bold ml-1">+</span>
          </p>
          <p class="text--disabled text-body-1 mb-0 text-center">
            {{ $t("来自国家") }}
          </p>
        </v-col>
        <v-card
          width="20"
          height="20"
          class="rounded-pill"
          flat
          color="#A9EAC8"
        ></v-card>
        <v-col cols="5" md="2" sm="4">
          <p
            class="font24 primary--text d-flex align-center mb-0 justify-center"
          >
            <span class="font48 font-weight-bold">10%</span
            ><span class="font24 font-weight-bold ml-1">+</span>
          </p>
          <p class="text--disabled text-body-1 mb-0 text-center">
            {{ $t("占全球电子商务总量") }}
          </p>
        </v-col>
        <v-card
          width="20"
          height="20"
          class="rounded-pill"
          flat
          color="#D595E9"
        ></v-card>
        <v-col cols="5" md="2" sm="4">
          <p
            class="font24 primary--text d-flex align-center mb-0 justify-center"
          >
            <span class="font48 font-weight-bold">2</span
            ><span class="font24 font-weight-bold ml-1">{{ $t("亿") }}+</span>
          </p>
          <p class="text--disabled text-body-1 mb-0 text-center">
            {{ $t("经济活动量") }}
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-card
      flat
      height="90"
      class="mt-4 rounded-0"
      width="100%"
      color="#d3cfff"
    >
      <router-link to="sign-up">
        <p
          class="text-center primary--text font28 mb-0"
          style="line-height: 90px"
        >
          {{ $t("点击免费开通") }}
        </p>
      </router-link>
    </v-card>
    <v-container class="pa-0">
      <p
        style="margin-top: 60px"
        class="text-center text-body-2 primary--text mb-3"
      >
        {{ $t("无论你是谁") }}
      </p>
      <p class="text-center font32 font-weight-bold mb-10">
        {{ $t("任何人都可以轻松经营taoify商店") }}
      </p>
      <v-img
        max-width="926"
        src="@/assets/images/home/home10.png"
        class="mx-auto"
      ></v-img>
    </v-container>
    <v-card
      class="rounded-0 text-center py-5"
      flat
      style="
        background: linear-gradient(207deg, #a692fd 0%, #6355ff 100%);
        margin-top: 80px;
      "
    >
      <p class="font32 white--text mb-2 text-center">
        {{ $t("在这里开启你的全球生意") }}
      </p>
      <p class="font20 mb-2 white--text text-center">
        {{
          $t(
            "您的想法，taoify为您实现"
          )
        }}
      </p>
      <v-btn large class="primary--text rounded-lg" to="/sign-up" depressed>{{
        $t("立即免费开通")
      }}</v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.gongneng {
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 1445px) {
  #home-tops {
    width: 1445px !important;
    margin: 0 auto;
    .left {
      margin-left: 120px;
    }
  }
}

</style>
